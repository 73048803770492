import React from "react"
import { graphql } from "gatsby"
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
  Legend,
  Area,
  ComposedChart,
} from "recharts"

import Layout from "../components/layout"
import Situation from "../components/situation"

export default ({
  data: {
    countryDataTimeseries: {
      dataPoints,
      confirmed,
      country,
      state,
      deathsIncrease,
      confirmedIncrease,
      recoveredIncrease,
      deaths,
      recovered,
    },
  },
}) => {
  const formatXAxis = x => x.split("/")[1]
  let barDataPoints = []
  dataPoints.forEach((today, index) => {
    if (index === 0) return
    const yesterday = dataPoints[index - 1]
    barDataPoints.push({
      date: today.date,
      confirmed: today.confirmed - yesterday.confirmed,
      deaths: today.deaths - yesterday.deaths,
      recovered: today.recovered - yesterday.recovered,
    })
  })
  const dataPointsWithActiveCases = dataPoints.map(x => ({
    ...x,
    active: parseInt(x.confirmed) - parseInt(x.deaths) - parseInt(x.recovered),
  }))
  return (
    <Layout>
      <h1>
        Covid19 situation for {country} {state ? ` - ${state}` : ""}
      </h1>
      <Situation
        style={{ marginTop: "2rem" }}
        confirmed={confirmed}
        deaths={deaths}
        recovered={recovered}
        deathsIncrease={deathsIncrease}
        confirmedIncrease={confirmedIncrease}
        recoveredIncrease={recoveredIncrease}
      />
      <h2 style={{ marginTop: "2rem" }}>Situation</h2>
      <ResponsiveContainer minWidth={300} minHeight={400}>
        <ComposedChart
          data={dataPointsWithActiveCases}
          margin={{ top: 0, right: 0, bottom: 0, left: 25 }}
        >
          <Line
            type="monotone"
            dataKey="confirmed"
            stroke="#0000ff"
            dot={false}
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="deaths"
            stroke="#ff0000"
            dot={false}
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="recovered"
            stroke="#00ff00"
            dot={false}
            strokeWidth={2}
          />
          <Area
            type="monotone"
            dataKey="active"
            stroke="#8884d8"
            strokeWidth={2}
            fill="#8884d8"
          />
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="date" tickCount={6} />
          <YAxis
            type="number"
            domain={[0, Math.ceil((confirmed / 100) * 1.1) * 100]}
            allowDecimals={false}
            dataKey="confirmed"
          />
          <Tooltip />
          <Legend />
        </ComposedChart>
      </ResponsiveContainer>
      <h2>Situation (Log scale)</h2>
      <ResponsiveContainer minWidth={300} minHeight={400}>
        <LineChart
          data={dataPointsWithActiveCases}
          margin={{ top: 0, right: 0, bottom: 0, left: 25 }}
        >
          <Line
            type="monotone"
            dataKey="confirmed"
            stroke="#0000ff"
            strokeWidth={2}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="deaths"
            stroke="#ff0000"
            dot={false}
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="recovered"
            stroke="#00ff00"
            strokeWidth={2}
            dot={false}
          />
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="date" tickCount={6} />
          <YAxis
            scale="log"
            domain={[10, Math.ceil((confirmed / 100) * 2) * 100]}
          />
          <Tooltip />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
      <h2>New cases per day</h2>
      <ResponsiveContainer minWidth={300} minHeight={400}>
        <BarChart data={barDataPoints}>
          <Bar dataKey="confirmed" stackId="a" fill="#0000ff" />
          <YAxis type="number" allowDecimals={false} domain={[0, "dataMax"]} />
          <XAxis dataKey="date" tickCount={6} />
          <Tooltip />
          <Legend />
        </BarChart>
      </ResponsiveContainer>
      <h2>New deaths per day</h2>
      <ResponsiveContainer minWidth={300} minHeight={400}>
        <BarChart data={barDataPoints}>
          <Bar dataKey="deaths" stackId="a" fill="#ff0000" />
          <YAxis type="number" allowDecimals={false} domain={[0, "dataMax"]} />
          <XAxis dataKey="date" tickCount={6} />
          <Tooltip />
          <Legend />
        </BarChart>
      </ResponsiveContainer>

      <h2>New recovered per day</h2>
      <ResponsiveContainer minWidth={300} minHeight={400}>
        <BarChart data={barDataPoints}>
          <Bar dataKey="recovered" stackId="a" fill="#00ff00" />
          <YAxis type="number" allowDecimals={false} domain={[0, "dataMax"]} />
          <XAxis dataKey="date" tickCount={6} />
          <Tooltip />
          <Legend />
        </BarChart>
      </ResponsiveContainer>
    </Layout>
  )
}

export const query = graphql`
  query template($id: String) {
    countryDataTimeseries(id: { eq: $id }) {
      country
      state
      dataPoints {
        date
        confirmed
        deaths
        recovered
      }
      confirmed
      deaths
      recovered
      deathsIncrease
      confirmedIncrease
      recoveredIncrease
    }
  }
`
